var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-notes" } }),
              _c(
                "h5",
                { staticClass: "d-inline ml-2" },
                [
                  _vm._v(" " + _vm._s(this.$route.meta.label) + " "),
                  _vm.isEdit
                    ? [
                        _vm._v(" : "),
                        _c(
                          "h3",
                          { staticClass: "d-inline" },
                          [
                            _c("CBadge", { attrs: { color: "secondary" } }, [
                              _vm._v(
                                _vm._s(this.itemName) +
                                  " | " +
                                  _vm._s(this.restaurantName)
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    : [
                        _vm._v(" to "),
                        _c(
                          "h3",
                          { staticClass: "d-inline" },
                          [
                            _c("CBadge", { attrs: { color: "secondary" } }, [
                              _vm._v(_vm._s(this.restaurantName)),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" Restaurant "),
                      ],
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "card-header-actions" },
                [
                  _c(
                    "CButton",
                    { attrs: { color: "dark", to: this.prevRoute } },
                    [
                      _c("CIcon", {
                        staticClass: "align-bottom",
                        attrs: { name: "cil-arrow-thick-left" },
                      }),
                      _vm._v(" Back "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c("alert-section", {
                attrs: {
                  successAlertMessage: _vm.successAlertMessage,
                  dismissSecs: _vm.dismissSecs,
                  dismissSuccessAlert: _vm.dismissSuccessAlert,
                  errorAlertMessage: _vm.errorAlertMessage,
                  showErrorAlert: _vm.showErrorAlert,
                },
              }),
              _c(
                "CForm",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { col: "6" } },
                        [
                          _c("CSelect", {
                            attrs: {
                              label: "Menu Category",
                              options: _vm.availableCategories,
                              value: _vm.$v.form.menu_cat.$model,
                              placeholder: "Please choose a category",
                              custom: "",
                              isValid: _vm.checkIfValid("menu_cat"),
                            },
                            on: {
                              "update:value": [
                                function ($event) {
                                  return _vm.$set(
                                    _vm.$v.form.menu_cat,
                                    "$model",
                                    $event
                                  )
                                },
                                _vm.changedMenuCategory,
                              ],
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { col: "6" } },
                        [
                          _c("CInput", {
                            attrs: {
                              label: "Custom ID",
                              placeholder: "Custom ID",
                              lazy: false,
                              value: _vm.$v.form.custom_id.$model,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.$v.form.custom_id,
                                  "$model",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.menuNames
                        ? _c("CCol", { attrs: { col: "12" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "alert alert-success",
                                attrs: { role: "alert" },
                              },
                              [
                                _c("h5", { staticClass: "alert-heading" }, [
                                  _vm._v("Menus in this category:"),
                                ]),
                                _vm._v(" " + _vm._s(_vm.menuNames) + " "),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "CCol",
                        { attrs: { col: "6" } },
                        [
                          _c("CInput", {
                            attrs: {
                              label: "Name",
                              placeholder: "Name",
                              lazy: false,
                              value: _vm.$v.form.menu_name.$model,
                              isValid: _vm.checkIfValid("menu_name"),
                              invalidFeedback:
                                "This is a required field and must be at least 2 characters",
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.$v.form.menu_name,
                                  "$model",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { col: "6" } },
                        [
                          _c("CInput", {
                            attrs: {
                              label: "Short Description",
                              placeholder: "Short Description",
                              lazy: false,
                              value: _vm.$v.form.description.$model,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.$v.form.description,
                                  "$model",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { col: "6" } },
                        [
                          _c("CInput", {
                            attrs: {
                              label: "Price",
                              placeholder: "0.00",
                              lazy: false,
                              value: _vm.$v.form.price.$model,
                              isValid: _vm.checkIfValid("price"),
                              invalidFeedback:
                                "This is a required field and must be numeric",
                              type: "number",
                              step: "0.01",
                              pattern: "^\\d+(?:\\.\\d{1,2})?$",
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.$v.form.price,
                                  "$model",
                                  $event
                                )
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend-content",
                                  fn: function () {
                                    return [
                                      _c("CIcon", {
                                        attrs: { name: "cil-euro" },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              586003441
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { col: "6" } },
                        [
                          _c("CInput", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: this.usetoprice === true,
                                expression: "this.usetoprice === true",
                              },
                            ],
                            attrs: {
                              label: "Takeout Price",
                              placeholder: "0.00",
                              lazy: false,
                              value: _vm.$v.form.toPrice.$model,
                              isValid: _vm.checkIfValid("toPrice"),
                              invalidFeedback:
                                "This is a required field and must be numeric",
                              type: "number",
                              step: "0.01",
                              pattern: "^\\d+(?:\\.\\d{1,2})?$",
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.$v.form.toPrice,
                                  "$model",
                                  $event
                                )
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend-content",
                                  fn: function () {
                                    return [
                                      _c("CIcon", {
                                        attrs: { name: "cil-euro" },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              586003441
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { col: "6" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-block",
                              staticStyle: { "font-weight": "500" },
                            },
                            [_vm._v("Turn")]
                          ),
                          _c("CSwitch", {
                            attrs: {
                              labelOn: "On",
                              labelOff: "Off",
                              color: "success",
                              shape: "pill",
                              lazy: false,
                              checked: _vm.$v.form.eol.$model,
                            },
                            on: {
                              "update:checked": function ($event) {
                                return _vm.$set(
                                  _vm.$v.form.eol,
                                  "$model",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c("CCol", { attrs: { md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "d-flex flex-column" },
                          [
                            _c(
                              "CMedia",
                              {
                                attrs: {
                                  "aside-image-props": {
                                    src: this.previewMenuImage,
                                    shape: "rounded",
                                    width: "100%",
                                    height: "120px",
                                  },
                                },
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "mb-2 font-weight-bold" },
                                  [_vm._v("Menu Image:")]
                                ),
                                _c("CInput", {
                                  staticClass: "mb-0",
                                  attrs: {
                                    value: _vm.$v.form.menu_image.$model,
                                    type: "hidden",
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.menu_image,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "btn btn-outline-info mb-0",
                                    attrs: { for: "img" },
                                  },
                                  [
                                    _c("CIcon", {
                                      attrs: { name: "cil-file" },
                                    }),
                                    _vm._v(" Browse "),
                                  ],
                                  1
                                ),
                                _c("CInputFile", {
                                  class: !_vm.checkIfValid("menu_image")
                                    ? "is-invalid"
                                    : "",
                                  staticStyle: { display: "none" },
                                  attrs: { id: "img" },
                                  on: { change: _vm.onFileChanged },
                                }),
                                !_vm.checkIfValid("menu_image")
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        !_vm.$v.form.menu_image.required
                                          ? _c(
                                              "div",
                                              { staticClass: "error" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "1.2rem",
                                                    },
                                                  },
                                                  [_vm._v("⚠️")]
                                                ),
                                                _vm._v(
                                                  " Please select an image for the menu! "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.previewMenuImage
                              ? _c(
                                  "CLink",
                                  {
                                    staticClass: "small mt-2 text-danger",
                                    on: {
                                      click: () => {
                                        this.selectedMenuImage =
                                          this.previewMenuImage = null
                                        this.form.menu_image = ""
                                      },
                                    },
                                  },
                                  [
                                    _c("CIcon", {
                                      attrs: { name: "cil-trash" },
                                    }),
                                    _vm._v(" Remove Image "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "sticky-search" }, [
                    _c("div", { staticClass: "input-group" }, [
                      _c("div", { staticClass: "input-group-prepend" }, [
                        _c(
                          "span",
                          { staticClass: "input-group-text" },
                          [
                            _c("CIcon", {
                              attrs: { name: "cil-magnifying-glass" },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.search,
                            expression: "search",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "search",
                          placeholder: "Search for menu groups..",
                        },
                        domProps: { value: _vm.search },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.search = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        {
                          staticClass: "mt-2 table-responsive",
                          attrs: { col: "12" },
                        },
                        [
                          _c("h5", [
                            _vm._v("Menu Groups (ingredients) Choice"),
                          ]),
                          _c("table", { staticClass: "table" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { attrs: { scope: "col" } }),
                                _c(
                                  "th",
                                  { attrs: { scope: "col" } },
                                  [
                                    _c("CIcon", {
                                      attrs: { name: "cil-move" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "th",
                                  {
                                    staticStyle: { width: "16vw" },
                                    attrs: { scope: "col" },
                                  },
                                  [_vm._v("Menu Groups")]
                                ),
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("Ingredients"),
                                ]),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.allMenuGroups, function (mg) {
                                return _c(
                                  "tr",
                                  {
                                    key: mg.id,
                                    class: _vm.isSelectedMenuGroup(mg.id)
                                      ? "selected-item"
                                      : "disable-item",
                                  },
                                  [
                                    _c(
                                      "td",
                                      { staticClass: "enable-item" },
                                      [
                                        _c("CInputCheckbox", {
                                          attrs: {
                                            addLabelClasses:
                                              "custom-checkbox-label",
                                            custom: "",
                                            checked: _vm.isSelectedMenuGroup(
                                              mg.id
                                            ),
                                          },
                                          on: {
                                            "update:checked": (value) =>
                                              _vm.selectMenuGroup(mg.id, value),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "move-item" },
                                      [
                                        _c("CIcon", {
                                          staticClass: "align-bottom",
                                          attrs: {
                                            name: "cil-move",
                                            "data-smg-id":
                                              _vm.getSelectedMenuGroupId(mg.id),
                                            "data-id": mg.id,
                                            size: "lg",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "vertical-align": "top !important",
                                        },
                                      },
                                      [
                                        _c("h5", [_vm._v(_vm._s(mg.Name))]),
                                        _c("span", [_vm._v(_vm._s(mg.Notes))]),
                                        _vm.isSelectedMenuGroup(mg.id)
                                          ? _c(
                                              "section",
                                              {
                                                staticClass: "border-top pt-3",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c(
                                                      "label",
                                                      { staticClass: "mr-1" },
                                                      [_vm._v("Type:")]
                                                    ),
                                                    _c(
                                                      "select",
                                                      {
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeTypeListing(
                                                              mg.id,
                                                              $event
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "SLT",
                                                            },
                                                          },
                                                          [_vm._v("ListBox")]
                                                        ),
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "CHK",
                                                            },
                                                            domProps: {
                                                              selected:
                                                                _vm.selectedTypeListing(
                                                                  mg.id,
                                                                  "CHK"
                                                                ),
                                                            },
                                                          },
                                                          [_vm._v("CheckBox")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group w-100",
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass: "d-block",
                                                      },
                                                      [_vm._v("Valid Days *")]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "day-group",
                                                      },
                                                      _vm._l(
                                                        _vm.days,
                                                        function (day, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-check form-check-inline mr-1 mt-1",
                                                            },
                                                            [
                                                              _c(
                                                                "CInputCheckbox",
                                                                {
                                                                  attrs: {
                                                                    label: day,
                                                                    checked: _vm
                                                                      .selectedValidDays(
                                                                        mg.id
                                                                      )
                                                                      ?.includes(
                                                                        index
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    "update:checked":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.updateValidDays(
                                                                          mg.id,
                                                                          index
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c("small", [
                                                      _vm._v(
                                                        "* All days are valid if none is selected"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c("td", { staticClass: "enable-item" }, [
                                      _c(
                                        "ul",
                                        [
                                          _vm._l(
                                            mg.menu_ext_group_childs,
                                            function (child) {
                                              return _c(
                                                "li",
                                                { key: child.id },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "for-checkbox",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.form
                                                                .selectedChilds,
                                                            expression:
                                                              "form.selectedChilds",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "checkbox",
                                                        },
                                                        domProps: {
                                                          value: {
                                                            menuExtGroupId:
                                                              mg.id,
                                                            menuExtGroupChildId:
                                                              child.id,
                                                          },
                                                          checked:
                                                            Array.isArray(
                                                              _vm.form
                                                                .selectedChilds
                                                            )
                                                              ? _vm._i(
                                                                  _vm.form
                                                                    .selectedChilds,
                                                                  {
                                                                    menuExtGroupId:
                                                                      mg.id,
                                                                    menuExtGroupChildId:
                                                                      child.id,
                                                                  }
                                                                ) > -1
                                                              : _vm.form
                                                                  .selectedChilds,
                                                        },
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              var $$a =
                                                                  _vm.form
                                                                    .selectedChilds,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? true
                                                                    : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = {
                                                                    menuExtGroupId:
                                                                      mg.id,
                                                                    menuExtGroupChildId:
                                                                      child.id,
                                                                  },
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm.form,
                                                                      "selectedChilds",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm.form,
                                                                      "selectedChilds",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm.form,
                                                                  "selectedChilds",
                                                                  $$c
                                                                )
                                                              }
                                                            },
                                                            function ($event) {
                                                              return _vm.selectingChild(
                                                                mg.id
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(child.Name) +
                                                          " [" +
                                                          _vm._s(
                                                            _vm._f(
                                                              "toCurrency"
                                                            )(child.Price)
                                                          ) +
                                                          "] "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          mg.menu_ext_group_childs.length == 0
                                            ? _c("li", [
                                                _c("strong", [
                                                  _vm._v(
                                                    "No menu group childs found!"
                                                  ),
                                                ]),
                                              ])
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "CCardFooter",
            { staticClass: "sticky-bottom" },
            [
              _c(
                "CButton",
                {
                  attrs: { color: "primary", disabled: _vm.submitted },
                  on: {
                    click: function ($event) {
                      return _vm.submit()
                    },
                  },
                },
                [_vm._v(" Submit ")]
              ),
            ],
            1
          ),
          _c("mc-spinner", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.submitted,
                expression: "submitted",
              },
            ],
            attrs: { opacity: 0.8, mtop: 32 + _vm.mcSpinnerMarginTop + "px" },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }